<template>
  <el-container>
    <el-header class="homeHeader">
      <el-row>
        <el-col>
          <div class="title">答题系统</div>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <div v-if="this.$router.currentRoute.path==='/'">
        <el-row>
          <el-col style="text-align: center">
            <div style="font-family: '华文行楷', serif;font-size: 20px">欢迎你，YT！</div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px" type="flex" justify="center" :gutter="10">
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3">
            <div>
              <el-cascader
                  v-model="questionTypeValue"
                  :options="QuestionTypeList"
                  placeholder="请选择题库"
                  style="width: 100%"
                  clearable></el-cascader>
            </div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3">
            <div>
              <el-button type="primary" style="width: 100%" @click="goStart()">开始答题</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col style="text-align: center">
            <div>
              <el-button type="primary" style="width: 150px" @click="$router.push('/search')">指定答题</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col style="text-align: center">
            <div>
              <el-button type="primary" style="width: 150px">设置</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <router-view/>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      QuestionTypeList: [],
      questionTypeValue: null,
    }
  },
  methods: {
    loadQuestionTypeList() {
      this.getRequest("/api/ques/typeList", {}).then(resp => {
        if (resp && resp.code === 200 && resp.data) {
          this.QuestionTypeList = resp.data.map((item) => ({
            value: item,
            label: item
          }));
        }
      })
    },
    goStart() {
      console.log(this.questionTypeValue)
      if (this.questionTypeValue != null) {
        this.$router.push({
          path: "/question",
          query: {
            questionTypeValue: this.questionTypeValue[0],
          }
        })
      } else {
        this.$message.error("请选择题库！")
      }
    }
  },
  computed: {},
  mounted() {
    this.loadQuestionTypeList()
  }
}
</script>

<style>
.homeHeader {
  background-color: #409eff;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
  height: 75px;
}

.homeHeader .title {
  font-size: 30px;
  font-family: "楷体", serif;
  color: #ffffff;
  text-align: center; /* 添加这一行 */
  flex: 1; /* 添加这一行 */
  display: flex; /* 添加这一行 */
  align-items: center; /* 添加这一行 */
  justify-content: center; /* 添加这一行 */
}
</style>