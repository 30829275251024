import Vue from 'vue'
import Router from 'vue-router'
import Home from "@/views/Home.vue";
import questionInfo from "@/views/QuestionInfo.vue";
import searchQuestion from "@/views/SearchQuestion.vue";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            hidden: true,
            children: [
                {
                    path: '/question',
                    name: 'questionInfo',
                    component: questionInfo,
                    hidden: true,
                },
                {
                    path: '/search',
                    name: 'searchQuestion',
                    component: searchQuestion,
                    hidden: true,
                }
            ]
        }, {
            path: '*',
            redirect: '/'
        }
    ]
})