import { render, staticRenderFns } from "./QuestionInfo.vue?vue&type=template&id=088be9e4&scoped=true&"
import script from "./QuestionInfo.vue?vue&type=script&lang=js&"
export * from "./QuestionInfo.vue?vue&type=script&lang=js&"
import style0 from "./QuestionInfo.vue?vue&type=style&index=0&id=088be9e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088be9e4",
  null
  
)

export default component.exports