<template>
  <div>
    <div class="search-container">
      <el-row class="search-row">
        <el-col :span="4">
          <el-input
              placeholder="输入题目Id"
              v-model="searchInfo.questionId"
              style="width: 100%"></el-input>
        </el-col>
        <el-col :span="5">
          <el-cascader
              v-model="questionType"
              :options="QuestionTypeList"
              placeholder="请选择题库"
              clearable
              filterable
          ></el-cascader>
        </el-col>
        <el-col :span="8">
          <el-input
              placeholder="请输入需要搜索题目的内容"
              prefix-icon="el-icon-search"
              v-model="searchInfo.questionContent"
              style="width: 100%"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" style="width: 100%" @click="loadQues">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <div>
        <el-table :data="questionList" style="width: 100%" height="500px" max-height="500px" stripe border>
          <el-table-column
              prop="questionId"
              label="题目Id"
              width="70">
          </el-table-column>
          <el-table-column
              prop="questionType"
              label="所属题库"
              width="150">
          </el-table-column>
          <el-table-column
              prop="questionSubType"
              label="题型"
              width="170">
          </el-table-column>
          <el-table-column
              prop="questionContent"
              :min-width="300"
              label="题目内容">
          </el-table-column>
          <el-table-column
              fixed="right"
              prop="date"
              label="操作"
              width="100">
            <template slot-scope="scope">
              <el-button @click="goStart(scope.row)" type="text" size="small">从这里开始</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
  </div>
</template>

<script>
import {removeWatermark, setWaterMark} from "@/utils/watermark";

export default {
  name: "SearchQuestion",
  data() {
    return {
      QuestionTypeList: [],
      questionList: [],
      questionType: null,
      searchInfo: {
        questionContent: null,
        questionTypeValue: null,
        questionId: null
      }
    };
  },
  methods: {
    goStart(row) {
      console.log(row.questionType)
      window.localStorage.removeItem(row.questionType)
      window.localStorage.setItem(row.questionType, row.questionId - 1)
      this.$router.push({
        path: "/question",
        query: {
          questionTypeValue: row.questionType,
        }
      })
    },
    loadQuestionTypeList() {
      this.getRequest("/api/ques/typeList", {}).then((resp) => {
        if (resp && resp.code === 200 && resp.data) {
          this.QuestionTypeList = resp.data.map((item) => ({
            value: item,
            label: item,
          }));
        }
      });
    },
    loadQues() {
      if (this.searchInfo.questionId === null && this.searchInfo.questionTypeValue === null
          && (this.searchInfo.questionContent === null || this.searchInfo.questionContent === "题目")) {
        this.$message.warning("请输入一些数据，没有数据的搜索会给系统带来巨大的负荷")
      } else {
        if (this.questionType !== null) {
          this.searchInfo.questionTypeValue = this.questionType[0]
        }

        this.postRequest("/api/ques/search", this.searchInfo).then(resp => {
          if (resp) {
            this.questionList = resp.data
            console.log(this.questionList)
          }
        })
      }
    },
    printInfo() {
      if (this.questionType !== null) {
        this.searchInfo.questionTypeValue = this.questionType[0]
      }
      console.log(this.searchInfo.questionTypeValue)
    }
  },
  computed: {},
  mounted() {
    this.loadQuestionTypeList();
    setWaterMark('系统使用权', 'YT');
    // this.loadQues();
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
</style>
