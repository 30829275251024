<template>
  <div>
    <div class="text-center">
      题库：{{ $route.query.questionTypeValue }}
    </div>
    <p v-html="currentQuestion.questionContent" class="text-center"/>

    <div>
      <answer-info-com :current-answer-list="currentQuestion.answerList"
                       :question-sub-type="currentQuestion.questionSubType" ref="answerCom" :key="componentKey"/>
    </div>
    <div>
      <el-row>
        <el-col style="text-align: center">
          <el-button type="primary" style="margin-top: 10px" @click="submit()" :disabled="buttonEnabled">提交
          </el-button>
          <el-button type="primary" style="margin-top: 10px" @click="$refs.answerCom.reset()" :disabled="buttonEnabled">
            重置
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-button type="primary" style="margin-top: 4px" @click="$router.push('/')">首页</el-button>
          <el-button type="primary" style="margin-top: 4px" @click="prevQues"
                     :disabled="questionIndex === 0||questionIndex === 19">上一题
          </el-button>
          <el-button type="primary" style="margin-top: 4px" @click="nextQues">下一题</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="position: absolute;bottom: 0;left: 0">
        <el-button style="color: #409eff;font-family: 楷体, serif" @click="clearLocalHistory">从头开始做题？点我！</el-button>
    </div>
    <div style="position: absolute;bottom: 0;right: 0">
      <el-tooltip class="item" effect="dark" :content="'id'+this.currentQuestion.questionId+'将该信息截图给管理员'"
                  placement="top-start">
        <el-button style="color: #409eff;font-family: 楷体, serif">选项内容出现问题了？点我！</el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import answerInfoCom from "../components/answerInfoCom.vue"
import {removeWatermark,setWaterMark} from "@/utils/watermark";

export default {
  name: "QuestionInfo",
  data() {
    return {
      buttonEnabled: false,
      currentQuestion: {
        questionId: null,
        questionType: null,
        questionSubType: null,
        questionContent: null,
        answerList: [
          {
            answerContent: null,
            answer: null
          },
          {
            answerContent: null,
            answer: null
          },
          {
            answerContent: null,
            answer: null
          },
          {
            answerContent: null,
            answer: null
          }
        ]
      },
      questionList: [],
      questionIndex: 0,
      questionId: 0,
      questionInfo: {
        questionTypeValue: this.$route.query.questionTypeValue,
        questionId: null
      },
      componentKey: 0,
    }
  },
  components: {
    answerInfoCom
  },
  methods: {
    submit() {
      this.$refs.answerCom.checkAnswer()
      this.buttonEnabled = true
    },
    loadQues() {
      let localQid=window.localStorage.getItem(this.$route.query.questionTypeValue)
      this.questionInfo.questionId = localQid === null ? 0 :localQid
          this.postRequest("/api/ques/getNew", this.questionInfo).then(resp => {
            if (resp) {
              console.log(resp.data)
              if(resp.data ===[]){
                this.clearLocalHistory()
              }
              this.questionList = resp.data
            }
            this.currentQuestion = this.questionList[this.questionIndex]
          })
    },
    nextQues() {
      if (this.questionIndex === 18) {
        this.questionIndex++
        this.currentQuestion = this.questionList[this.questionIndex]
        this.questionInfo.questionId = this.currentQuestion.questionId
        //留本地记录，下次做题从这里开始
        window.localStorage.setItem(this.currentQuestion.questionType,this.currentQuestion.questionId)
        this.postRequest("/api/ques/getNew", this.questionInfo).then(resp => {
          if (resp) {
            this.questionList = resp.data
          }
        })
      } else if (this.questionIndex === 19) {
        this.questionIndex = 0
        this.currentQuestion = this.questionList[this.questionIndex]
      } else {
        this.questionIndex++
        this.currentQuestion = this.questionList[this.questionIndex]
      }
      this.buttonEnabled = false
      this.componentKey += 1;
    },
    prevQues() {
      this.buttonEnabled = false
      this.questionIndex--
      this.currentQuestion = this.questionList[this.questionIndex]
      this.componentKey += 1
    },
    clearLocalHistory(){
      window.localStorage.removeItem(this.currentQuestion.questionType)
      this.$router.go(0)
    }
  },
  mounted() {
    this.loadQues()
    setWaterMark('系统使用权', 'YT');
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
