<template>
  <div>
    <el-checkbox-group v-model="currentCheck" :max="this.questionSubType === '多选题' ? this.currentAnswerList.length : 1" style="margin-top: 6px;"
                       :disabled="this.groupDisable">
      <div v-for="(item, index) in currentAnswerList" :key="index">
        <el-row style="margin-top: 8px">
          <el-col :xs="{span:16,offset:4}" :sm="{span:12,offset:6}" :md="{span:12,offset:6}" :lg="{span:8,offset:8}"
                  :xl="{span:8,offset:8}">
            <el-checkbox :label="index" @change="printInfo" border :id="'box'+index">
              <div class="answer-content" :id="'text'+index">{{ item.answerContent }}</div>
            </el-checkbox>
          </el-col>
        </el-row>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "answerInfoCom",
  props: ['currentAnswerList', 'questionSubType'],
  data() {
    return {
      currentCheck: [],
      groupMaxSize: 1,
      groupDisable: false
    }
  },
  methods: {
    reset() {
      this.$forceUpdate()
      this.currentCheck = []
      this.groupDisable = false
    },
    judgmentSubType() {
      this.groupMaxSize = this.questionSubType === "多选题" ? this.currentAnswerList.length : 1
    },
    checkAnswer() {
      console.log("checkAnswer")
      this.groupDisable = true
      //判断对错
      for (let item of this.currentCheck) {
        const el = document.getElementById("box" + item);
        const eltext = document.getElementById("text" + item);
        if (this.currentAnswerList[item].answer) {
          el.setAttribute('style', 'background-color: #5CB87A')
          eltext.setAttribute('style', 'color: #FFFFFF')
          //该el-checkbox变成绿色背景色
        } else {
          //该el-checkbox变成红色背景色
          el.setAttribute('style', 'background-color: red')
          eltext.setAttribute('style', 'color: #FFFFFF')
        }
      }

      //显示正确答案
      for (let [index, item] of this.currentAnswerList.entries()) {
        const el = document.getElementById("box" + index);
        const eltext = document.getElementById("text" + index);
        if (item.answer) {
          el.setAttribute('style', 'background-color: #5CB87A')
          eltext.setAttribute('style', 'color: #FFFFFF')
        }
      }
    },
    printList() {//dev
      console.log(this.currentAnswerList);
      console.log(this.questionSubType)
    },
    printInfo() {//dev
      console.log(this.currentCheck);
    }
  },
  mounted() {
    this.judgmentSubType()
    this.printList();
  }
}
</script>

<style scoped>
.el-checkbox {
  width: 100%;
  height: 100%;
}

.answer-content {
  white-space: normal;
}
</style>
